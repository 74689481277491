import moment from "moment";
import React, { useEffect, useRef, useState } from "react";
import { Link } from "react-router-dom";
import { useAuth } from "../../context/Auth";
import strings from "../../utils/strings";
import { API, endpoints } from "../../api";
import { debounce } from "lodash";

export const Notifications = () => {
  const { notificationCount, user, userCheck } = useAuth();
  const divRef = useRef(null); // Reference to the div

  const [loading, setLoading] = useState(false);
  const [notifications, setNotifications] = useState([]);

  const createMarkup = (msg) => {
    return {
      __html: msg,
    };
  };

  const getNotifications = () => {
    setLoading(true);
    API.get({ route: endpoints.NOTIFICATIONS })
      .then((data) => {
        setNotifications(data?.data?.data);
      })
      .finally(() => setLoading(false));
  };

  const debounceGetNotifications = debounce(getNotifications, 100)

  useEffect(() => {
    const divElement = divRef.current;

    // Callback for when mutations are observed
    const callback = (mutationsList) => {
      mutationsList.forEach((mutation) => {
        if (mutation.type === 'attributes' && mutation.attributeName === 'style') {
          const display = window.getComputedStyle(divElement).display;
          if (display === 'block') {
            console.log('Div is now visible (block)');
            debounceGetNotifications()
            // Your logic when the div becomes visible
          } else if (display === 'none') {
            console.log('Div is now hidden (none)');
          }
        }
      });
    };

    // Create a MutationObserver instance
    const observer = new MutationObserver(callback);

    // Start observing the target div for style attribute changes
    if (divElement) {
      observer.observe(divElement, { attributes: true });
    }

    // Cleanup the observer on component unmount
    return () => {
      if (observer) {
        observer.disconnect();
      }
    };
  }, []);



  if (!user?.user) {
    return (
      <a
        className="p-2 text-center ms-auto menu-icon cursor-pointer bell-icon-main"
        onClick={() => userCheck()}
      >
        <i className="feather-bell font-xl text-white"></i>
      </a>
    );
  }

  return (
    <>
      <a
        className="p-2 text-center ms-auto menu-icon cursor-pointer"
        id="dropdownMenu3"
        data-bs-toggle="dropdown"
        aria-expanded="false"
      >
        {notificationCount ? (
          <span className="dot-count bg-warning"></span>
        ) : (
          ""
        )}
        <i className="feather-bell font-xl text-white"></i>
      </a>


      <div
        ref={divRef}
        className="dropdown-menu dropdown-menu-end p-4 rounded-3 border-0 shadow-lg"
        aria-labelledby="dropdownMenu3"
        style={{ minWidth: 420 }}
      >
        <h4 className="fw-700 font-xss mb-4">Notifications</h4>
        {
          loading ? <div style={{ textAlign: 'center' }}>
            <p style={{ visibility: 'hidden', margin: 0, padding: 0 }}>{strings.noNotifications}</p>
            <img src="./images/preloader.svg" />
          </div> : <>
            {
              notifications?.length ? (
                <div className="notifications">
                  {notifications?.map(({ id, data, created_at }) => (
                    <a
                      key={`notification-${id}`}
                      href={data?.custom_data?.feed_url}
                      className="card bg-transparent-card w-100 border-0 ps-5 mb-3 notification-single row"
                    >
                      <div className="col-lg-8 d-flex align-items-center">
                        <img
                          src={data?.custom_data?.img}
                          alt="user"
                          className="w40 position-absolute left-0 rounded-xl"
                        />
                        <h5
                          dangerouslySetInnerHTML={createMarkup(
                            data?.custom_data?.msg
                          )}
                          className="font-xssss text-grey-900 mb-1 mt-0 fw-700 d-block"
                        ></h5>
                      </div>
                      <div className="col-lg-3">
                        <span className="time font-xsssss fw-600 float-right mt-1">
                          {moment(created_at).fromNow()}
                        </span>
                      </div>
                    </a>
                  ))}
                </div>
              ) : (
                <p>{strings.noNotifications}</p>
              )
            }
          </>
        }
      </div>
    </>
  );
};
