import {
  CardElement,
  Elements,
  useElements,
  useStripe,
} from "@stripe/react-stripe-js";
import { loadStripe } from "@stripe/stripe-js";
import React, { useMemo, useState } from "react";
import { useNavigate } from "react-router-dom";
import { API, endpoints } from "../../api";
import { Loader } from "../../components";
import { useAuth } from "../../context/Auth";
import strings, { toastType } from "../../utils/strings";
const stripePromise = loadStripe(process.env.REACT_APP_STRIPE_KEY);
const CheckoutForm = ({ setTermsError, termsChecked, goToSuccessPage }) => {
  const [loading, setLoading] = useState(false);
  const [submitted, setSubmitted] = useState(false);
  const stripe = useStripe();
  const elements = useElements();
  // const [clientSecret, setClientSecret] = useState(false);

  const navigate = useNavigate();

  const { checkSubscription, loginRedirectUrl, subscriptionData, showToast, user } = useAuth();


  const handleSubmit = async (event) => {
    event.preventDefault();

    if (!user?.user?.country || !user?.user?.country_code) {
      return showToast({ text: strings.noCountry, type: toastType.ERROR });
    }

    if (!termsChecked) {
      return setTermsError(true);
    }
    setLoading(true);
    try {
      const { error, paymentMethod } = await stripe.createPaymentMethod({
        type: "card",
        card: elements.getElement(CardElement),
      });
      if (error) {
        return setLoading(false);
      }

      console.log("error", error);
      if (paymentMethod) {
        const cardElement = elements.getElement(CardElement);
        let { token } = await stripe.createToken(cardElement);

        var data = new FormData();
        data.append("stripeToken", token?.id);

        API.post({ route: endpoints.SUBSCRIPTION, data }).then(async (data) => {
          console.log("data => ", data)
          if (!data?.status) {
            showToast({
              text: data?.message,
              type: toastType.ERROR,
            });
            return
          }
          const { type, clientSecret, subscription_mode, feed_id } = data?.result || {};


          if (subscription_mode) {
            if (type === 'setup') {
              const { paymentIntent, error } = await stripe.confirmCardSetup(
                clientSecret,
                {
                  payment_method: {
                    card: {
                      token: token?.id,
                    },
                  },
                },
              );
              if (paymentIntent?.status === "succeeded") {
                goToSuccessPage(feed_id)
              }

              if (error?.message) {
                showToast({
                  text: error?.message,
                  type: toastType.ERROR,
                });
              }

              console.log(" paymentIntent, error => ", paymentIntent, error)
            }
            else {
              const { paymentIntent, error } = await stripe.confirmCardPayment(
                clientSecret,
                {
                  payment_method: paymentMethod?.id,
                },
              );
              if (paymentIntent?.status === "succeeded") {
                goToSuccessPage(feed_id)
              }
              if (error?.message) {
                showToast({
                  text: error?.message,
                  type: toastType.ERROR,
                });
              }
              console.log("paymentIntent, error => ", paymentIntent, error)
            }
          } else {
            goToSuccessPage(feed_id)
          }


        }).finally(() => setLoading(false))

      } else {
        setSubmitted(true)
        setLoading(false);
      }
    } catch (e) {
      console.log("e   => ", e)
      setSubmitted(true)
      setLoading(false);
    }
  };

  return (
    <>
      <form onSubmit={handleSubmit}>
        <div className="stripe-form pt-3" id="step2">
          <div className="form-row">
            <label htmlFor="card-element text-red" style={{ fontSize: 16, color: '#f00', fontWeight: '500' }}>
              Type your Card Number and Expiry Date below:
            </label>
          </div>

          <div className="shadow p-3 mb-5" style={{ background: '#1E74FD', borderRadius: 10 }}>
            <CardElement
              className="StripeElement"
              options={{
                style: {
                  // complete: {
                  //   color: '#26783c',
                  // },
                  base: {
                    padding: 10,
                    lineHeight: 2,
                    // backgroundColor: 'rgba(0,0,0,.1)',
                    fontSize: "18px",
                    color: "#fff",
                    "::placeholder": {
                      color: "#aab7c4",
                    },
                  },
                  invalid: {
                    color: "#9e2146",
                  },
                },
              }}
            />
            <div style={{ textAlign: "center" }} className="mt-2">
              <button
                disabled={submitted || loading}
                className="ms-auto mt-2 mb-2 border-0 p-2 lh-20 w250 bg-mini-gradiant text-white text-center font-xssss fw-600 ls-1 rounded-xl"
                name="submintbtn2"
              >
                SUBSCRIBE
              </button>
            </div>
          </div>

        </div>
      </form>
      {loading ? <Loader /> : null}
    </>
  );
};
const PaymentForm = ({ setTermsError, termsChecked, goToSuccessPage }) => {

  const { loginRedirectUrl, subscriptionData } = useAuth();

  if (!subscriptionData?.plan?.price_id) {
    return null
  }

  return (

    <div>
      <Elements stripe={stripePromise}
        options={subscriptionData?.plan?.subscription_payment ? {
          mode: 'subscription',
          amount: parseFloat(subscriptionData?.plan?.amount || 0),
          currency: (subscriptionData?.plan?.currency || '')?.toString()?.toLowerCase(),
          priceId: subscriptionData?.plan?.price_id,
        } : {}}
      >
        <CheckoutForm setTermsError={setTermsError} termsChecked={termsChecked} goToSuccessPage={goToSuccessPage} />
      </Elements>
    </div>
  )
};
export default PaymentForm;
