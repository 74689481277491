export const errorToString = (error) => {
  if (!error) return ''
  let finalError = "";

  if (typeof error?.data?.error?.message === "string") {
    finalError = error?.data?.error?.message;
  } else if (typeof error?.data?.errors === "string") {
    finalError = error?.data?.errors;
  } else if (error?.data?.errors) {
    let err = error?.data?.errors;
    Object.keys(err).forEach((key) => {
      let msg = "";
      if (typeof err[key] === "string") {
        msg = err[key];
      } else if (Array.isArray(err[key])) {
        msg = err[key].join(", ");
      }
      if (finalError) {
        finalError += `<br />`;
      }
      finalError += `${key} : ${msg}`;
    });
  }

  return (
    finalError ||
    "There is some error to complete the request, please try again ..."
  );
};

export const nFormatter = (num, digits = 1) => {
  const lookup = [
    { value: 1, symbol: "" },
    { value: 1e3, symbol: "k" },
    { value: 1e6, symbol: "M" },
    { value: 1e9, symbol: "G" },
    { value: 1e12, symbol: "T" },
    { value: 1e15, symbol: "P" },
    { value: 1e18, symbol: "E" },
  ];
  const rx = /\.0+$|(\.[0-9]*[1-9])0+$/;
  var item = lookup
    ?.slice()
    ?.reverse()
    ?.find(function (item) {
      return num >= item.value;
    });
  return item
    ? (num / item.value).toFixed(digits).replace(rx, "$1") + item.symbol
    : "0";
};

export const getParams = (data) => {
  return Object.keys(data)
    .map((key) => `${key}=${encodeURIComponent(data[key])}`)
    .join("&");
};

export const validateUrl = (url) => {
  if (url.includes("http")) {
    return url;
  } else {
    return "https://" + url;
  }
};

export function deleteAllParams() {
  window.history.replaceState(null, "", window.location.pathname);
}


export const isEventManager = false

export const convertMentions = (text, isPlanText = false) => {
  // Regular expression to match @[Name](user:id)
  // const mentionRegex = /@\[([^\]]+)\]\(user:(\d+)\)/g;
  const mentionRegex = /@\[([^\]]+)\]\(user:(\w+)\)/g;
  // const mentionRegex = /@\[[^\]]+\]\(user:([^\)]+)\)/g;
  // "content": "@[Jassu ](user:6047)",
  // "content": "this is tag @[Jassu ](user:JassuBhai4454661)",

  // Replace each mention with <span> element
  const convertedText = text.replace(mentionRegex, (match, name, id) => {
    if (isPlanText) return name
    return `<a href="/user/${id}" class="tagged-user" id="${id}">${name}</a>`;
  });

  return convertedText;
}