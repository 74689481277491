import React from "react";
import sideLinks from "./links";
import { Nav } from "./nav";
import { useAuth } from "../../context/Auth";
import { Link, useNavigate } from "react-router-dom";
import Ad from "../ad";
import { Avatar } from "../avatar";
import RightEvents from "../right-events";
import { Authorization, LogoutModal } from "../authorization";

export const Sidebar = () => {
  const {
    togglePremiumModal,
    user,
    subscriptionData,
    userCheck,
    isNavOpen,
    setNavOpen,
  } = useAuth();

  const navigate = useNavigate();

  return (
    <>
      {isNavOpen ? (
        <div className="mobile-open-nav-bg">
          <i
            onClick={() => setNavOpen(false)}
            className="feather-x-circle text-grey-900 font-sm btn-round-md bg-greylight modal-close-btn"
          />
        </div>
      ) : null}

      <nav className={`navigation scroll-bar ${isNavOpen ? "nav-active" : ""}`}>
        <div className="container ps-0 pe-0">
          <div className="nav-content">
            {user?.user ? (
              <Link className="login-user" to={`/user/${user?.user?.user_name || user?.user?.id}`}>
                <a class="p-2 text-center ms-3 menu-icon center-menu-icon">
                  {/* {user?.user?.image ? (
                  <img className="w50 btn-round-lg" src={user?.user?.image} />
                ) : null} */}
                  <Avatar user={user?.user} className={"w50 h50"} />
                </a>
                <h4 class="fw-700 text-grey-900 font-xss mt-1 header-username text-current">
                  {user?.user?.pref_first_name || user?.user?.first_name} {" "} {user?.user?.last_name}
                  {/* <span class="d-block font-xssss fw-500 lh-3 text-grey-500">
                    {user?.user?.email}
                  </span> */}
                  {user &&
                    subscriptionData &&
                    subscriptionData?.isSubscribed ? (
                    <span class="premium-badge d-block">Premium</span>
                  ) : null}
                </h4>
              </Link>
            ) : null}
            {user && subscriptionData && !subscriptionData?.isSubscribed ? (
              <button
                class="ms-auto mt-2 mb-2 border-0 p-2 lh-20 w250 bg-mini-gradiant text-white text-center font-xssss fw-600 ls-1 rounded-xl"
                onClick={togglePremiumModal}
              >
                Purchase Premium Membership
              </button>
            ) : null}
            {sideLinks({ user, navigate, userCheck, subscriptionData })?.map(
              (item, index) => (
                <Nav key={`sidebar-${index}`} {...item} />
              )
            )}

            <RightEvents />
            <div className="show-lg">
              {user ? <LogoutModal /> : null}
            </div>
            {/* <a
              href="https://paul7852.wixsite.com/pa-comp-coach?fbclid=IwAR0o_0crnQAWkt0sLAcHldSl8gj0oRP8mP2APKUl_QY8IE3apRgQToT28m0"
              target="_blank"
              class="card w-100 shadow-xss rounded-xxl border-0 mb-3 mt-3 bg-none"
            >
              <img src="./images/advert1.jpg" className="rounded-xxl" />
            </a> */}
            <div class="widget badges">
              <h3> Status Icons Index </h3>
              <ul>
                <li>
                  <span class="badge-wrapper">
                    <span class="contests-number-badge">10</span>
                  </span>
                  <span class="badge-description">
                    Contests entered in iCompete
                  </span>
                </li>
                <li>
                  <span class="badge-wrapper">
                    <span class="representing-badge" />
                  </span>
                  <span class="badge-description">Represented Country</span>
                </li>
                <li>
                  <span class="badge-wrapper">
                    <span class="pq-badge" />
                  </span>
                  <span class="badge-description">
                    Eligible to enter PRO Qualifiers
                  </span>
                </li>
                <li>
                  <span class="badge-wrapper">
                    <span class="professional-badge" />
                  </span>
                  <span class="badge-description">Professional Competitor</span>
                </li>
                <li>
                  <span class="badge-wrapper">
                    <span class="vip-badge" />
                  </span>
                  <span class="badge-description">Official</span>
                </li>
              </ul>
            </div>
            {/* <div className="App">
              <Ad
                path="/37678685/IC_Left_Bottom_250X250"
                width={250}
                height={250}
              />
            </div> */}
            <ul className="mt-3 mb-3">
              <li className="small-link">
                <Link onClick={() => setNavOpen(false)} to="/terms-and-conditions">
                  <span class="badge-description">
                    Privacy - Terms - Safety
                  </span>
                </Link>
              </li>
              <li className="small-link">
                <Link onClick={() => setNavOpen(false)} to="/code-of-ethics">
                  <span class="badge-description">Code of Ethics</span>
                </Link>
              </li>
              <li className="small-link">
                <Link onClick={() => setNavOpen(false)} to="/contact">
                  <span class="badge-description">
                    Help / Contact Us - Advertising
                  </span>
                </Link>
              </li>
            </ul>
            <div class="widget">
              <div class="copyright">Copyright © 2016 - 2023 Natural Icons</div>
            </div>
          </div>
        </div>
      </nav>
    </>
  );
};
