export default ({ user, navigate, userCheck, subscriptionData }) => [
  {
    title: "MEMBERSHIP",
    data: [
      {
        name: "My Profile",
        icon: "btn-round-sm bg-blue-gradiant me-2 fa-solid fa-user",
        // href: "/edit-profile",
        // link: true,
        onClick: () => {
          userCheck(() => {
            navigate(`/edit-profile`);
          });
        },
      },
      {
        name: "My Business",
        icon: "btn-round-sm bg-blue-gradiant me-2 fa-solid fa-chart-simple",
        // href: "/business",
        // link: true,

        onClick: () => {
          userCheck(() => {
            navigate(`/business`);
          });
        },
      },
      user?.user && {
        name: "Messages",
        icon: "btn-round-sm bg-blue-gradiant me-2 fa-solid fa-comment",
        // href: "/change-password",
        // link: true,

        onClick: () => {
          userCheck(() => {
            navigate(`/messages`);
          });
        },
      },
      user?.user && {
        name: "Email Preferences",
        icon: "btn-round-sm bg-blue-gradiant me-2 fa-solid fa-envelope",
        // href: "/change-password",
        // link: true,

        onClick: () => {
          userCheck(() => {
            navigate(`/communication`);
          });
        },
      },

      user?.user &&
      (subscriptionData?.subscription?.status ||
        subscriptionData?.expiryDate) && {
        name: "Membership",
        icon: "btn-round-sm bg-blue-gradiant me-2 fa-solid fa-money-bill",
        // href: "/change-password",
        // link: true,

        onClick: () => {
          userCheck(() => {
            navigate(`/subscription-status`);
          });
        },
      },

      user &&
      !user?.user?.social_signup && {
        name: "Change Password",
        icon: "btn-round-sm bg-blue-gradiant me-2 fa-solid fa-key",
        // href: "/change-password",
        // link: true,

        onClick: () => {
          userCheck(() => {
            navigate(`/change-password`);
          });
        },
      },
    ],
  },
  {
    title: "EVENTS",
    // info: "/my-event",
    data: [
      {
        name: "Full Calendar",
        icon: "btn-round-sm bg-blue-gradiant me-2 fa-solid fa-calendar-days",
        href: "/calendar",
        link: true,
      },
      {
        name: "Event Information",
        icon: "btn-round-sm bg-blue-gradiant me-2 fa-solid fa-info",
        href: "/events",
        link: true,
      },
      {
        name: "Enter New Event",
        icon: "btn-round-sm bg-blue-gradiant me-2 fa-solid fa-calendar-check",
        href: "/enter-new-event",
        link: true,
      },
      {
        name: "View Entry",
        icon: "btn-round-sm bg-blue-gradiant me-2 fa-solid fa-magnifying-glass",
        // href: "/view-events",
        // link: true,
        onClick: () => {
          userCheck(() => {
            navigate(`/view-events`);
          });
        },
      },
      {
        name: "Edit Entry",
        icon: "btn-round-sm bg-blue-gradiant me-2 fa-solid fa-edit",
        // href: "/pay-events",
        // link: true,
        onClick: () => {
          userCheck(() => {
            navigate(`/edit-events`);
          });
        },
      },
      {
        name: "Pay Entry",
        icon: "btn-round-sm bg-blue-gradiant me-2 fa-solid fa-money-check",
        // href: "/pay-events",
        // link: true,
        onClick: () => {
          userCheck(() => {
            navigate(`/pay-events`);
          });
        },
      },
      {
        name: "Invoices",
        icon: "btn-round-sm bg-blue-gradiant me-2 fa-solid fa-dollar-sign",
        onClick: () => {
          userCheck(() => {
            navigate(`/invoices`);
          });
        },
      },
      // {
      //   name: "Tickets",
      //   icon: "btn-round-sm bg-blue-gradiant me-2 fa-solid fa-ticket",
      //   href: "",
      // },
      // {
      //   name: "Transformation ",
      //   icon: "btn-round-sm bg-blue-gradiant me-2 fa-brands fa-instagram",
      //   href: "",
      // },
    ],
  },
  {
    title: "KNOWLEDGE",
    data: [
      {
        name: "Categories",
        icon: "btn-round-sm bg-blue-gradiant me-2 fa-solid fa-list",
        href: "https://icn-members-qa.s3.ap-southeast-2.amazonaws.com/resources/Portal%2BPage%2B-%2BCategories.pdf",
        blank: true,
      },
      {
        name: "Male Divisions",
        icon: "btn-round-sm bg-blue-gradiant me-2 fa-solid fa-mars",
        data: [
          {
            name: "Bodybuilding",
            icon: "me-2 text-primary fa-solid fa-caret-right",
            href: "https://icn1.s3.ap-southeast-2.amazonaws.com/pdf/Bodybuilding.pdf",
            blank: true,
          },
          {
            name: "Classic Physique",
            icon: "me-2 text-primary fa-solid fa-caret-right",
            href: "https://icn1.s3.ap-southeast-2.amazonaws.com/pdf/Classic-Bodybuilding.pdf",
            blank: true,
          },
          {
            name: "Men’s Athletic",
            icon: "me-2 text-primary fa-solid fa-caret-right",
            href: "https://icn1.s3.ap-southeast-2.amazonaws.com/pdf/Mens-Athletic.pdf",
            blank: true,
          },
          {
            name: "Men’s Fitness",
            icon: "me-2 text-primary fa-solid fa-caret-right",
            href: "https://icn1.s3.ap-southeast-2.amazonaws.com/pdf/Mens-Fitness-Model.pdf",
            blank: true,
          },
          {
            name: "Men’s Physique",
            icon: "me-2 text-primary fa-solid fa-caret-right",
            href: "https://icn1.s3.ap-southeast-2.amazonaws.com/pdf/Mens-Physique.pdf",
            blank: true,
          },
          {
            name: "Streetwear",
            icon: "me-2 text-primary fa-solid fa-caret-right",
            href: "https://icn1.s3.ap-southeast-2.amazonaws.com/pdf/Mens-Street-Model.pdf",
            blank: true,
          },
          {
            name: "Physically Challenged",
            icon: "me-2 text-primary fa-solid fa-caret-right",
            href: "https://icn1.s3.ap-southeast-2.amazonaws.com/pdf/PC.pdf",
            blank: true,
          },
          {
            name: "Transformation",
            icon: "me-2 text-primary fa-solid fa-caret-right",
            href: "https://icn1.s3.ap-southeast-2.amazonaws.com/pdf/Transformation-9-&-10.pdf",
            blank: true,
          },
        ],
      },
      {
        name: "Female Divisions",
        icon: "btn-round-sm bg-blue-gradiant me-2 fa-solid fa-venus",
        data: [
          {
            name: "Bikini Model",
            icon: "me-2 text-primary fa-solid fa-caret-right",
            href: "https://icn1.s3.ap-southeast-2.amazonaws.com/pdf/Bikini.pdf",
            blank: true,
          },
          {
            name: "Fitness Model",
            icon: "me-2 text-primary fa-solid fa-caret-right",
            href: "https://icn1.s3.ap-southeast-2.amazonaws.com/pdf/Ms-Fitness-Model.pdf",
            blank: true,
          },
          {
            name: "Sports Model",
            icon: "me-2 text-primary fa-solid fa-caret-right",
            href: "https://icn1.s3.ap-southeast-2.amazonaws.com/pdf/Ms-Sports-Model.pdf",
            blank: true,
          },
          {
            name: "Swimsuit Model",
            icon: "me-2 text-primary fa-solid fa-caret-right",
            href: "https://icn1.s3.ap-southeast-2.amazonaws.com/pdf/Swimsuit%20Model.pdf",
            blank: true,
          },
          {
            name: "ICN Angels",
            icon: "me-2 text-primary fa-solid fa-caret-right",
            href: "https://icn1.s3.ap-southeast-2.amazonaws.com/pdf/ICN-Angels.pdf",
            blank: true,
          },
          {
            name: "Ms Runway",
            icon: "me-2 text-primary fa-solid fa-caret-right",
            href: "https://icn1.s3.ap-southeast-2.amazonaws.com/pdf/Ms-Runway.pdf",
            blank: true,
          },
          {
            name: "Streetwear",
            icon: "me-2 text-primary fa-solid fa-caret-right",
            href: "https://icn1.s3.ap-southeast-2.amazonaws.com/pdf/MS-Street-Model.pdf",
            blank: true,
          },
          {
            name: "Ms Figure",
            icon: "me-2 text-primary fa-solid fa-caret-right",
            href: "https://icn1.s3.ap-southeast-2.amazonaws.com/pdf/Ms-Figure.pdf",
            blank: true,
          },
          {
            name: "Ms Wellness",
            icon: "me-2 text-primary fa-solid fa-caret-right",
            href: "https://icn1.s3.ap-southeast-2.amazonaws.com/pdf/Ms-Wellness.pdf",
            blank: true,
          },
          {
            name: "Classic Figure",
            icon: "me-2 text-primary fa-solid fa-caret-right",
            href: "https://icn1.s3.ap-southeast-2.amazonaws.com/pdf/Classic-Figure.pdf",
            blank: true,
          },
          {
            name: "Gym Model",
            icon: "me-2 text-primary fa-solid fa-caret-right",
            href: "https://icn1.s3.ap-southeast-2.amazonaws.com/pdf/Gym-Model.pdf",
            blank: true,
          },
        ],
      },
      {
        name: "PRO Qualifier Card (PQ)",
        icon: "btn-round-sm bg-blue-gradiant me-2 fa-solid",
        text: "PQ",
        href: "https://icn1.s3.ap-southeast-2.amazonaws.com/pdf/PQ+Card+Portal+PDF.pdf",
        blank: true,
      },
      {
        name: "PROS",
        icon: "btn-round-sm bg-blue-gradiant me-2 fa-solid fa-star",
        href: "https://icn1.s3.ap-southeast-2.amazonaws.com/pdf/Portal+Page+-+PROS.pdf",
        blank: true,
      },
      {
        name: "Rules",
        icon: "btn-round-sm bg-blue-gradiant me-2 fa-solid fa-circle-check",
        href: "https://icn1.s3.ap-southeast-2.amazonaws.com/pdf/Portal+Page+Rules.pdf",
        blank: true,
      },
      {
        name: "History",
        icon: "btn-round-sm bg-blue-gradiant me-2 fa-solid fa-clock-rotate-left",
        href: "https://icn1.s3.ap-southeast-2.amazonaws.com/pdf/Portal+Page+-+History.pdf",
        blank: true,
      },
    ],
  },
  {
    title: "DRUG TESTING ",
    data: [
      {
        name: "ICN Anti-Drug Policy",
        icon: "me-2 text-primary fa-solid fa-caret-right",
        href: "https://icn1.s3.ap-southeast-2.amazonaws.com/pdf/2023_Anti_Doping_Policy.pdf",
        blank: true,
      },
      {
        name: "Therapeutic Use (TUE)",
        icon: "me-2 text-primary fa-solid fa-caret-right",
        href: "https://icn1.s3.ap-southeast-2.amazonaws.com/pdf/TUE-2018.pdf",
        blank: true,
      },
      {
        name: "Report Cheating",
        icon: "me-2 text-primary fa-solid fa-caret-right",
        href: "/report-cheating",
        link: true,
      },
      {
        name: "Check Your Substance",
        icon: "me-2 text-primary fa-solid fa-caret-right",
        href: "https://www.globaldro.com/AU/search",
        blank: true,
      },
    ],
  },
  {
    title: "RESOURCES",
    data: [
      {
        name: "Fitness Directory",
        icon: "btn-round-sm bg-blue-gradiant me-2 fa-solid fa-location-dot",
        href: "/fitness-directory",
        link: true,
      },
      {
        name: "Online Store (coming 2025)",
        icon: "btn-round-sm bg-blue-gradiant me-2 fa-solid fa-store",
        blank: true,
      },
      {
        name: "Bodybuilding Course",
        icon: "btn-round-sm bg-blue-gradiant me-2 fa-solid fa-dumbbell",
        href: "https://bodybuildingcourse.com/",
        blank: true,
      },
      {
        name: "Contest Diet Course",
        icon: "btn-round-sm bg-blue-gradiant me-2 fa-solid fa-person-running",
        href: "https://onfit.edu.au/courses/personal-professional-development/bodybuilding-contest-prep-diet",
        blank: true,
      },
    ],
  },
  {
    title: "CONTACT US",
    data: [
      {
        name: "Help Videos ",
        icon: "btn-round-sm bg-blue-gradiant me-2 fa-solid fa-question",
        data: [
          {
            name: "Timeline Posts",
            icon: "btn-round-xss me-2 fa-solid bg-none",
            data: [
              {
                name: "Create Post",
                icon: "ml-11 me-2 text-primary fa-solid fa-caret-right",
                href: "https://www.youtube.com/watch?v=EirZMvcwSTY",
                blank: true,
              },
              {
                name: "Edit Post",
                icon: "ml-11 me-2 text-primary fa-solid fa-caret-right",
                href: "https://www.youtube.com/watch?v=-YLcKodDITU",
                blank: true,
              },
              {
                name: "Add Comment",
                icon: "ml-11 me-2 text-primary fa-solid fa-caret-right",
                href: "https://www.youtube.com/watch?v=69K_luw9wgs",
                blank: true,
              },
              {
                name: "Edit Comment",
                icon: "ml-11 me-2 text-primary fa-solid fa-caret-right",
                href: "https://www.youtube.com/watch?v=fvFt3icLJR4",
                blank: true,
              },
              {
                name: "Delete Comment",
                icon: "ml-11 me-2 text-primary fa-solid fa-caret-right",
                href: "https://www.youtube.com/watch?v=6jBv3EzXma0",
                blank: true,
              },
              {
                name: "Delete Post",
                icon: "ml-11 me-2 text-primary fa-solid fa-caret-right",
                href: "https://www.youtube.com/watch?v=NJGkW-ZGi3U",
                blank: true,
              },
              {
                name: "Post To Member Timeline",
                icon: "ml-11 me-2 text-primary fa-solid fa-caret-right",
                href: "https://www.youtube.com/watch?v=iddy-i7L9YU",
                blank: true,
              },
            ],
          },
          {
            name: "Membership",
            icon: "btn-round-xss me-2 fa-solid bg-none",
            data: [
              {
                name: "New Member Sign Up",
                icon: "ml-11 me-2 text-primary fa-solid fa-caret-right",
                href: "https://www.youtube.com/watch?v=2-TaBUMrP2Q",
                blank: true,
              },
              {
                name: "Upgrade To Premium",
                icon: "ml-11 me-2 text-primary fa-solid fa-caret-right",
                href: "https://www.youtube.com/watch?v=rPpz25aQvOU",
                blank: true,
              },
              {
                name: "Transformation (TFX) Membership",
                icon: "ml-11 me-2 text-primary fa-solid fa-caret-right",
                href: "https://www.youtube.com/watch?v=gKxo5qok2NE",
                blank: true,
              },
              {
                name: "Upgrade From TFX to Premium",
                icon: "ml-11 me-2 text-primary fa-solid fa-caret-right",
                href: "https://www.youtube.com/watch?v=FuZx9pziNt0",
                blank: true,
              },
            ],
          },
          {
            name: "Messages  ",
            icon: "btn-round-xss me-2 fa-solid bg-none",
            data: [
              {
                name: "Send Private Message",
                icon: "ml-11 me-2 text-primary fa-solid fa-caret-right",
                href: "https://www.youtube.com/watch?v=HntNMZZByOY",
                blank: true,
              },
              {
                name: "Reply To Message",
                icon: "ml-11 me-2 text-primary fa-solid fa-caret-right",
                href: "https://www.youtube.com/watch?v=pHdjMVmYguQ",
                blank: true,
              },
              {
                name: "Block And Unblock Senders",
                icon: "ml-11 me-2 text-primary fa-solid fa-caret-right",
                href: "https://www.youtube.com/watch?v=Nmz9Eg6aJ4U",
                blank: true,
              },
            ],
          },
          {
            name: "My Profile   ",
            icon: "btn-round-xss me-2 fa-solid bg-none",
            data: [
              {
                name: "Edit Profile",
                icon: "ml-11 me-2 text-primary fa-solid fa-caret-right",
                href: "https://www.youtube.com/watch?v=ChB1kldEiqc",
                blank: true,
              },
              {
                name: "Profile Photo",
                icon: "ml-11 me-2 text-primary fa-solid fa-caret-right",
                href: "https://www.youtube.com/watch?v=Cci5eQh21f8",
                blank: true,
              },
              {
                name: "Email Preferences",
                icon: "ml-11 me-2 text-primary fa-solid fa-caret-right",
                href: "https://www.youtube.com/watch?v=2JjBnMltSzo",
                blank: true,
              },
            ],
          },
          {
            name: "My Business",
            icon: "btn-round-xss me-2 fa-solid bg-none",
            href: "https://www.youtube.com/watch?v=1mxEPLmsQZg",
            blank: true,
          },
          {
            name: "My Transformation ",
            icon: "btn-round-xss me-2 fa-solid bg-none",
            data: [
              {
                name: "Transformation Story",
                icon: "ml-11 me-2 text-primary fa-solid fa-caret-right",
                href: "https://www.youtube.com/watch?v=LXGbK2sYtgs",
                blank: true,
              },
              {
                name: "Before And After Photo",
                icon: "ml-11 me-2 text-primary fa-solid fa-caret-right",
                href: "https://www.youtube.com/watch?v=beyihu-rTD4",
                blank: true,
              },
            ],
          },
          {
            name: "Help ",
            icon: "btn-round-xss me-2 fa-solid bg-none",
            data: [
              {
                name: "General Help",
                icon: "ml-11 me-2 text-primary fa-solid fa-caret-right",
                href: "https://www.youtube.com/watch?v=vywa8Bx8rYU",
                blank: true,
              },
              {
                name: "Regional Help",
                icon: "ml-11 me-2 text-primary fa-solid fa-caret-right",
                href: "https://www.youtube.com/watch?v=-ucfI1CoxFg",
                blank: true,
              },
            ],
          },
          {
            name: "Event Information",
            icon: "btn-round-xss me-2 fa-solid bg-none",
            href: "https://www.youtube.com/watch?v=Lzi_NmY8ipU",
            blank: true,
          },
          {
            name: "Enter New Event  ",
            icon: "btn-round-xss me-2 fa-solid bg-none",
            data: [
              {
                name: "Enter New Event",
                icon: "ml-11 me-2 text-primary fa-solid fa-caret-right",
                href: "https://www.youtube.com/watch?v=4e6IrsnxBrw",
                blank: true,
              },
              {
                name: "Event Registration",
                icon: "ml-11 me-2 text-primary fa-solid fa-caret-right",
                href: "https://www.youtube.com/watch?v=CESh0uDf_Qo",
                blank: true,
              },
            ],
          },
          {
            name: "Edit Entry",
            icon: "btn-round-xss me-2 fa-solid bg-none",
            href: "https://www.youtube.com/watch?v=VXcYzsEypRg",
            blank: true,
          },
          {
            name: "Pay",
            icon: "btn-round-xss me-2 fa-solid bg-none",
            href: "https://www.youtube.com/watch?v=W4lOC_FHqQY",
            blank: true,
          },
          {
            name: "My History",
            icon: "btn-round-xss me-2 fa-solid bg-none",
            data: [
              {
                name: "Events",
                icon: "ml-11 me-2 text-primary fa-solid fa-caret-right",
                href: "https://www.youtube.com/watch?v=LM5JmI7m5CA",
                blank: true,
              },
              {
                name: "Invoices",
                icon: "ml-11 me-2 text-primary fa-solid fa-caret-right",
                href: "https://www.youtube.com/watch?v=WHy6z8zeyj4",
                blank: true,
              },
            ],
          },

          {
            name: "Search Fitness Resources",
            icon: "btn-round-xss me-2 fa-solid bg-none",
            href: "https://www.youtube.com/watch?v=Q3tw30KWgs0",
            blank: true,
          },
          {
            name: "Other",
            icon: "btn-round-xss me-2 fa-solid bg-none",
            data: [
              {
                name: "Change Language",
                icon: "ml-11 me-2 text-primary fa-solid fa-caret-right",
                href: "https://www.youtube.com/watch?v=OoNPWkEtqp4",
                blank: true,
              },
              {
                name: "Report Doping",
                icon: "ml-11 me-2 text-primary fa-solid fa-caret-right",
                href: "https://www.youtube.com/watch?v=xZ9jKRBpE2A",
                blank: true,
              },
              {
                name: "Upcoming Events",
                icon: "ml-11 me-2 text-primary fa-solid fa-caret-right",
                href: "https://www.youtube.com/watch?v=KhBd2SiGVUs",
                blank: true,
              },
              {
                name: "Promote Availability Map",
                icon: "ml-11 me-2 text-primary fa-solid fa-caret-right",
                href: "https://www.youtube.com/watch?v=Dh0yN0EPntI",
                blank: true,
              },
              {
                name: "Advertising",
                icon: "ml-11 me-2 text-primary fa-solid fa-caret-right",
                href: "https://www.youtube.com/watch?v=hbQ9Ttp2tfM",
                blank: true,
              },
              {
                name: "Promote Inquiry",
                icon: "ml-11 me-2 text-primary fa-solid fa-caret-right",
                href: "https://www.youtube.com/watch?v=FWDKj3UWxDc",
                blank: true,
              },
              {
                name: "Log Out",
                icon: "ml-11 me-2 text-primary fa-solid fa-caret-right",
                href: "https://www.youtube.com/watch?v=6JNQcgg15fA",
                blank: true,
              },
            ],
          },
        ],
      },
      {
        name: "Help / Contact Us",
        icon: "btn-round-sm bg-blue-gradiant me-2 fa-solid fa-question",
        href: "/contact",
        link: true,
      },
    ],
  },
];
